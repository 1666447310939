import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth/authContext.tsx";

interface PrivateRouteProps {
    children: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    const { authorise } = useAuth();
    return authorise() ? children : <Navigate to="/login" />;
};

export default PrivateRoute;