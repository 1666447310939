import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL ||
    (window.location.hostname === "chessgtm.com"
        ? `${window.location.protocol}//${window.location.hostname}/api`
        : `${window.location.protocol}//${window.location.hostname}:8100`);

const sendGET = (endpoint) => {
    return axios.get(`${API_BASE_URL}/${endpoint}`)
                .then((response) => response.data)
                .catch((error) => {
                    console.error("GET request error:", error);
                    return Promise.reject(error);
                });
};

const sendPOST = (endpoint, data) => {
    return axios.post(`${API_BASE_URL}/${endpoint}`, data)
                .then((response) => response.data)
                .catch((error) => {
                    console.error("POST request error:", error);
                    return Promise.reject(error);
                });
};

export { sendGET, sendPOST };
