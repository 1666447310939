import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for the context
interface AvatarContextType {
    avatarUrl: string | null;
    setAvatarUrl: (url: string | null) => void;
}

// Create the context with default values
const AvatarContext = createContext<AvatarContextType | undefined>(undefined);

// Define the type for the provider's props
interface AvatarProviderProps {
    children: ReactNode;
}

// Create the context provider
export const AvatarProvider: React.FC<AvatarProviderProps> = ({ children }) => {
    const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

    return (
        <AvatarContext.Provider value={{ avatarUrl, setAvatarUrl }}>
            {children}
        </AvatarContext.Provider>
    );
};

// Create a hook to use the context easily
export const useAvatar = () => {
    const context = useContext(AvatarContext);
    if (!context) {
        throw new Error('useAvatar must be used within an AvatarProvider');
    }
    return context;
};
