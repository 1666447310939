import React from 'react';
import { AuthorisedHeader } from '../header/AppHeader.tsx';

const PrivateLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <>
            <AuthorisedHeader />
            <div>{children}</div>
        </>
    );
};

export default PrivateLayout;