import React from 'react';
import './Button1.css';

interface ButtonProps {
    type?: 'button' | 'submit' | 'reset';
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
    type = 'button',
    children,
    className = '',
    onClick,
    disabled = false
}) => {
    return (
        <button type={type}
                className={`button ${className}`}
                onClick={onClick}
                disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;

