// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
    background-color: #3861fb;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 14px 16px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    display: inline-block;
    text-align: center;
    text-decoration: none;
}

.button:hover {
    background-color: #2e50d4;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.button:active {
    background-color: #2540b2;
    transform: scale(0.98);
}

.button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    box-shadow: none;
}

/* Modifier styles */
.button.small {
    padding: 8px 12px;
    font-size: 12px;
}

.button.large {
    padding: 16px 20px;
    font-size: 16px;
}

.button.outline {
    background-color: transparent;
    color: #3861fb;
    border: 2px solid #3861fb;
}

.button.outline:hover {
    background-color: #3861fb;
    color: #fff;
}

`, "",{"version":3,"sources":["webpack://./src/components/buttons/Button1.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf,iFAAiF;IACjF,qBAAqB;IACrB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,yCAAyC;AAC7C;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA,oBAAoB;AACpB;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,6BAA6B;IAC7B,cAAc;IACd,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf","sourcesContent":[".button {\n    background-color: #3861fb;\n    color: #fff;\n    border: none;\n    border-radius: 8px;\n    padding: 14px 16px;\n    font-weight: 600;\n    font-size: 14px;\n    cursor: pointer;\n    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;\n    display: inline-block;\n    text-align: center;\n    text-decoration: none;\n}\n\n.button:hover {\n    background-color: #2e50d4;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);\n}\n\n.button:active {\n    background-color: #2540b2;\n    transform: scale(0.98);\n}\n\n.button:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n    box-shadow: none;\n}\n\n/* Modifier styles */\n.button.small {\n    padding: 8px 12px;\n    font-size: 12px;\n}\n\n.button.large {\n    padding: 16px 20px;\n    font-size: 16px;\n}\n\n.button.outline {\n    background-color: transparent;\n    color: #3861fb;\n    border: 2px solid #3861fb;\n}\n\n.button.outline:hover {\n    background-color: #3861fb;\n    color: #fff;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
