import React from 'react';

interface PublicLayoutProps {
    children: React.ReactNode;
    HeaderComponent: React.ComponentType;
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children, HeaderComponent }) => {
    return (
        <>
            <HeaderComponent />
            <div>{children}</div>
        </>
    );
};

export default PublicLayout;