import "./AppHeader.css";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../buttons/Button1.tsx";

const AppHeader: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <header className="header">
            <div className="logo-container">
                <Link to="/" className="logo">
                    <img src="/icons/logo.svg" alt="ChessGTM" />
                </Link>
            </div>
            <div className="child-container">
                {children}
            </div>
        </header>
    );
};

const CreateAccountHeader: React.FC = () => {
    return (
        <AppHeader>
            <Button>
                Create Account
            </Button>
        </AppHeader>
    );
};

const SignInHeader: React.FC = () => {
    return (
        <AppHeader>
            <Button>
                Sign In
            </Button>
        </AppHeader>
    );
};

const AuthorisedHeader: React.FC = () => {
    return (
        <AppHeader>
        </AppHeader>
    );
};

const BlankHeader: React.FC = () => {
    return (
        <AppHeader>
        </AppHeader>
    );
};

export { AppHeader, AuthorisedHeader, BlankHeader, CreateAccountHeader, SignInHeader };