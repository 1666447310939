import "./index.css";
import React from "react";
import App from "./App.tsx";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/auth/authContext.tsx";
import { AvatarProvider } from "./contexts/avatarContext.tsx";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
    <React.StrictMode>
        <AuthProvider>
            <AvatarProvider>
                <App />
            </AvatarProvider>
        </AuthProvider>
    </React.StrictMode>
);

reportWebVitals();