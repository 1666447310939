// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    position: relative;
    background-color: #272735;
    color: #21130d;
    top: 0;
    left: 0;
    width: 100%;
    line-height: 19px;
    margin-top: 0;
    padding: 0 11px 0 0;
    right: 0;    
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    box-sizing: border-box;
    height: 75px;
}

.logo-container {
    float: left;
    height: 100%;
}

.child-container {
    float: right;
    height: 100%;
}

.logo {
    float: left;
    padding: 15px 33px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/header/AppHeader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,MAAM;IACN,OAAO;IACP,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,wCAAwC;IACxC,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,oBAAoB;AACxB","sourcesContent":[".header {\n    position: relative;\n    background-color: #272735;\n    color: #21130d;\n    top: 0;\n    left: 0;\n    width: 100%;\n    line-height: 19px;\n    margin-top: 0;\n    padding: 0 11px 0 0;\n    right: 0;    \n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    z-index: 1000;\n    box-sizing: border-box;\n    height: 75px;\n}\n\n.logo-container {\n    float: left;\n    height: 100%;\n}\n\n.child-container {\n    float: right;\n    height: 100%;\n}\n\n.logo {\n    float: left;\n    padding: 15px 33px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
