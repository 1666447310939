import React, { Suspense, lazy, useEffect } from "react";
import PrivateRoute from "./components/router/PrivateRoute.tsx";
import PublicLayout from "./components/router/PublicLayout.tsx";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateLayout from "./components/router/PrivateLayout.tsx";
import { AuthProvider, useAuth } from "./contexts/auth/authContext.tsx";
import { BlankHeader, CreateAccountHeader, SignInHeader } from "./components/header/AppHeader.tsx";

const MainPage = lazy(() => import("./pages/MainPage.tsx"));
const IndexPage = lazy(() => import("./pages/IndexPage.tsx"));
const AdminPage = lazy(() => import("./pages/AdminPage.tsx"));
const LoginPage = lazy(() => import("./pages/LoginPage.tsx"));
const LogoutPage = lazy(() => import("./pages/LogoutPage.tsx"));
const RegisterPage = lazy(() => import("./pages/RegisterPage.tsx"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage.tsx"));
const GuessTrainerPage = lazy(() => import("./pages/GuessTrainerPage.tsx"));

const MainRender: React.FC = () => {
    return <PrivateLayout>
               <MainPage />
           </PrivateLayout>;
}

const IndexRender: React.FC = () => {
    return <PublicLayout HeaderComponent={CreateAccountHeader}>
               <IndexPage />
           </PublicLayout>;
}

// Custom route logic for "/" route
const RootRoute: React.FC = () => {
    const { authorise } = useAuth();
    return authorise() ? <MainRender /> : <IndexRender />;
};

const AppWithAuthProvider: React.FC = () => {
    useEffect(() => {
        document.title = "ChessGTM - Guess the Move";
    }, []);

    return (
        <AuthProvider>
            <BrowserRouter>
                <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path="/" element={<RootRoute />} />
                        <Route path="/register" element={
                            <PublicLayout HeaderComponent={SignInHeader}>
                                <RegisterPage />
                            </PublicLayout>
                        } />
                        <Route path="/login" element={
                            <PublicLayout HeaderComponent={BlankHeader}>
                                <LoginPage />
                            </PublicLayout>
                        } />
                        <Route path="/logout" element={
                            <PublicLayout HeaderComponent={BlankHeader}>
                                <LogoutPage />
                            </PublicLayout>
                        } />
                        <Route path="/dashboard" element={
                            <PrivateRoute>
                                <MainRender />
                            </PrivateRoute>
                        } />
                        <Route path="/guess" element={
                            <PublicLayout HeaderComponent={BlankHeader}>
                                <GuessTrainerPage />
                            </PublicLayout>
                        } />
                        <Route path="/admin" element={
                            <PrivateRoute>
                                <PrivateLayout>
                                    <AdminPage />
                                </PrivateLayout>
                            </PrivateRoute>
                        } />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </AuthProvider>
    );
};

export default AppWithAuthProvider;