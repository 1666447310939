import { getEnvironment } from "./environment.ts";

export function getLocalStorageKey(name : string) {
	return 'chessgtm.web.' + getEnvironment() + '.' + name;
}

export function localStorageGet(key : string) {
	return localStorage.getItem(getLocalStorageKey(key));
}

export function localStorageSet(key : string, value : any) {
	return localStorage.setItem(getLocalStorageKey(key), value);
}

export function localStorageRemove(key : string) {
	return localStorage.removeItem(getLocalStorageKey(key));
}