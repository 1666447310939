import axios from "axios";
import { sendPOST } from "../../utils/utils.tsx";
import React, { createContext, useContext, useState, useEffect } from "react";
import { localStorageGet, localStorageSet, localStorageRemove } from "../../utils/localStorage.ts"; 

interface RegisterData {
    login: string;
    password: string;
    firstName: string;
    lastName: string;
};

interface LoginData {
    email: string;
    password: string;
};

interface AuthContextProps {
    authorise: () => any;
    register: (data: RegisterData) => Promise<void>;
    login: (data: LoginData) => Promise<void>;
    logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Name of the key for locally stored authentication details
const LOCAL_AUTH_KEY = "auth";

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [loaded, setLoaded] = useState(false);
    const [authUser, setAuthUser] = useState(null);

    useEffect(() => {
        setLoaded(true);
        _authorise(true);
    }, []);

    const _authorise = (loaded: boolean) => {
        if (authUser) {
            return authUser;
        }
        
        const data = localStorageGet(LOCAL_AUTH_KEY);
        const user = data ? JSON.parse(data) : null;
        
        if (loaded) {
            setAuthUser(user);
        }

        return user;
    };

    const authorise = () => {
        return _authorise(loaded);
    };

    const register = async (data: RegisterData) => {
        try {
            const user = await sendPOST("auth/register", {
                login: data.login,
                password: data.password,
                firstName: data.firstName,
                lastName: data.lastName
            });

            // Registration successful
            setAuthUser(user);
        } catch (error: any) {
            console.error("Registration error:", error);
            const errorMessage = error.response?.data?.message || "Registration failed";
            throw new Error(errorMessage);
        }
    };

    const login = async (data: LoginData) => {
        try {
            const user = await sendPOST("auth/login", {
                login: data.email,
                password: data.password
            });

            // Registration successful
            setAuthUser(user);

            // Save authentication to cache
            localStorageSet(LOCAL_AUTH_KEY, JSON.stringify(user));
        } catch (error: any) {
            console.error("Login error:", error);
            const errorMessage =
                error.response?.data?.message || "Invalid username or password.";
            throw new Error(errorMessage);
        }
    };

    const logout = async () => {
        try {
            await sendPOST("auth/logout", {});
            setAuthUser(null);
            localStorageRemove(LOCAL_AUTH_KEY);
        } catch (error) {
            console.error("Error logging out:", error);
            throw error;
        }
    };

    const authContextValue: AuthContextProps = {
        authorise,
        register,
        login,
        logout,
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {children}
        </AuthContext.Provider>
    );
};